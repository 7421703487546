import { connect } from "react-redux";
import CorporateSite from "app/pages/CorporateSite/CorporateSite.jsx";

const mapStateToProps = state => {
	return {
		resolution: state.resolution,
	};
};

export default connect(mapStateToProps)(CorporateSite);
