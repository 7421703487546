import { PureComponent } from "react";
import ShopSwitcherContainer from "app/pages/Home/ShopSwitcher/ShopSwitcherContainer";
import ShopSwitcher from "app/pages/Home/ShopSwitcher/ShopSwitcher";
import LogoBrandContainer from "app/pages/.shared/LogoBrand/LogoBrandContainer";

class CorporateSiteHeader extends PureComponent {
	render() {
		return (
			<header className="corporate-site__header">
				<div className="corporate-site__logo">
					<LogoBrandContainer isWhite={true} />
				</div>
				<div className="corporate-site__header__items">
					<ShopSwitcherContainer renderComponent={props => <ShopSwitcher {...props} />} />
				</div>
			</header>
		);
	}
}

export default CorporateSiteHeader;
