import { memo, useCallback, useEffect, useState } from "react";
import "./corporate-site.scss";
import BackgroundProgressiveImg from "app/pages/.shared/BackgroundProgressiveImg";
import { resolutionPropType } from "app/utils/propTypes";
import { RESOLUTION } from "app/constants";
import HeadMetadata from "app/utils/metas/HeadMetadata";
import SearchBookingContainer from "app/pages/CorporateSite/SearchBooking/SearchBookingContainer";
import RecoverBookingDataContainer from "app/pages/CorporateSite/RecoverBookingData/RecoverBookingDataContainer";
import Flipper from "app/pages/.shared/Animations/Flipper.jsx";
import { AutoplayCarousel } from "app/pages/.shared/AutoplayCarousel/AutoplayCarousel";
import {
	sendTagOnRecoverBookingDataPageLoad,
	sendTagOnSearchBookingPageLoad,
} from "app/utils/analytics";
import LogoBrand from "app/pages/.shared/LogoBrand/LogoBrand";
import CorporateSiteHeader from "app/pages/CorporateSite/CorporateSiteHeader";

const BACKGROUND_IMG_SIZES = {
	small: { width: 750, height: 1335 },
	medium: { width: 1920, height: 1080 },
	large: { width: 1920, height: 1080 },
	xlarge: { width: 1920, height: 1080 },
};

const CLOUDINARY_OPTIONS = ["c_fill"];

const PARTNERS = [
	{
		brand: "AF",
		shop: "fr-FR",
	},
	{
		brand: "VP",
		shop: "fr-FR",
	},
	{
		brand: "EK",
		shop: "fr-FR",
	},
	{
		brand: "HP",
		shop: "fr-FR",
	},
	{
		brand: "DD",
		shop: "fr-CH",
	},
	{
		brand: "MS",
		shop: "fr-CH",
	},
	{
		brand: "VC",
		shop: "fr-FR",
	},
	{
		brand: "CD",
		shop: "fr-FR",
	},
	{
		brand: "LS",
		shop: "fr-FR",
	},
	{
		brand: "TO",
		shop: "fr-FR",
	},
	{
		brand: "GP",
		shop: "en-GB",
	},
	{
		brand: "SE",
		shop: "fr-FR",
	},
	{
		brand: "SE",
		shop: "en-GB",
	},
	{
		brand: "SP",
		shop: "fr-FR",
	},
];

const CorporateSite = ({ resolution }) => {
	const [isSearch, setSearch] = useState(true);

	useEffect(() => {
		sendTagOnSearchBookingPageLoad();
	}, []);

	const onClickRecover = useCallback(() => {
		sendTagOnRecoverBookingDataPageLoad();
		setSearch(false);
	}, []);

	const onClickCross = useCallback(() => {
		sendTagOnSearchBookingPageLoad();
		setSearch(true);
	}, []);

	const renderFrontForm = useCallback(props => {
		return (
			<div className="corporate-site__form" {...props}>
				<HeadMetadata title="page.title.search.booking" />
				<SearchBookingContainer onClickRecover={onClickRecover} />
			</div>
		);
	}, []);

	const renderBackForm = useCallback(props => {
		return (
			<div className="corporate-site__form" {...props}>
				<i className="icon icon--cross" onClick={onClickCross} />
				<HeadMetadata title="page.title.recover.booking.data" />
				<RecoverBookingDataContainer onRecoverSuccess={onClickCross} />
			</div>
		);
	}, []);

	let backgroundImage =
		"https://res.cloudinary.com/perfectstay/image/upload/v1519208707/brands/PS/ishan-seefromthesky.png";

	if (resolution === RESOLUTION.SMALL) {
		backgroundImage =
			"https://res.cloudinary.com/perfectstay/image/upload/v1519208707/brands/PS/ishan-seefromthesky-mobile.png";
	} else if (resolution === RESOLUTION.MEDIUM) {
		backgroundImage =
			"https://res.cloudinary.com/perfectstay/image/upload/v1519208707/brands/PS/ishan-seefromthesky.png";
	}

	return (
		<div className="corporate-site">
			<CorporateSiteHeader />
			<div className="corporate-site__form-container">
				<Flipper
					isFront={isSearch}
					frontComponent={renderFrontForm}
					backComponent={renderBackForm}
					config={{ duration: 500 }}
				/>
				<div className="corporate-site__carousel">
					<AutoplayCarousel duration={40000} slideWidth={210}>
						{PARTNERS.map(({ shop, brand }) => {
							return (
								<div className="corporate-site__carousel__item " key={brand}>
									<LogoBrand isWhite shop={shop} brand={brand} />
								</div>
							);
						})}
					</AutoplayCarousel>
				</div>
			</div>
			<div className="corporate-site__photo">
				<BackgroundProgressiveImg
					sizes={BACKGROUND_IMG_SIZES}
					src={backgroundImage}
					thumbnail="iVBORw0KGgoAAAANSUhEUgAAADIAAAAcCAMAAAAURxzFAAABHVBMVEWN3NOV4tma49qs6+SY4di98ey17+mK29KQ3dSp6eLB8u2v7OaH2dCy7ei47+qH3dYFdYSF2tIEb36l59+f5NwKur/I8+7E8u6U39a78OsDTF4Dh5Wh5t8DjpoEq7MDQlIDR1gGUmMFV2gFaXkCmqaS4dmN4dkDPU0EZXUFYHAEsLcGtbuI4doFW2wDn6qD39kDeokOv8NP1tQDp68Ek58COEkCf40ElqEEo60ozMx03dcEgpCW29OJ5uAZxcd63tlD1dQ80M6c3NUfycoy0NBa3Nls29YnxcVj2dR+3dek6eITw8YQsbVn39tY1tMVuLoRpqyb5t904t4bv8Ck49yg4NmW5d+R5d595N8zy8pF0c4RjJcTmqKD5N8Pf4u11TR0AAACXklEQVQ4y3XSa1cSURgF4AMMKDIwDgMBRpnKxQuG5jUdxUtR0E0SpKL4/z+jvd9zDgNr1buW3+ZZe2+OqlJZKT2zVyqtJJO5VDaT7g+ensZXV39+fP/y/s3Nu7f3158Oi8W93Zfnr1UOpDQDEJVcKpPoVwdPY0tubkUcalEjkZgSgRbZBEMormxIlFE7A0kiZgYqptYgNm7YENSC2NPi+IUQuaQACtaK1RuNll5yq8Xu7pGI5yoHk9TfE1Ckg0EcpCUEte6vZcbRee0M4lKlYORyAij61YKEtEwtIzAD4PRApbSxQIu4kHaHIai1IFZVNktkExLpdBAUhEBcMIRDRLDUwWo+rzJZInxvQbUwC+lyO2sZgYj81pZKZIh0JQARs5CvDEGtObG9rRIwOCQEQ1wBwnE06ertEOeR2NlR6YQcEoYjXjwed2Ig7d7FR10LL8jlRuzvq7S54XB0h3N4QjpdUwtvfqaXU2xuqiAgCAKKMLxzRo7uZUM4ZF5sbCguDqoYPYIIw/mQXz/vWYvTL0+tWFtTVZ6MpgjLIuqttg5hLRkSiVeqIIfN5TBcDsu8OnpJyIfHYlTLChL+Rg6+XOZR8Bkl5FFCUItDrFhX/N6R7xWOSj+8hHz7LCG6lhHrSgIg1BKOquF5+k0oGDJXi6KpNIBwXZdqScS/Q0Q0pYpkuNp4ntfrdP8TQtGUAfgT4fq+53s91DIhDwyZLoacKFcGSIiPWwh5ePh9PIl+Li1AXK4mEQETLQGZTKazVyQ5MYTn2xDP9BJhyA56kTQN0cb3DYl+YSHT6ar5d7TkLwObx1Yk9xAzAAAAAElFTkSuQmCC"
					cloudinaryOptions={CLOUDINARY_OPTIONS}
				/>
			</div>
		</div>
	);
};

CorporateSite.propTypes = {
	resolution: resolutionPropType,
};

export default memo(CorporateSite);
